export const VEHICLE_MIME_TYPE = {
  // MPEG: 'MPEG',
  MP4: 'MP4',
  JPEG: 'JPEG',
  JPG: 'JPG',
  PNG: 'PNG',
} as const;

export const VEHICLE_MEDIA_AREAS = {
  CONSOLE: 'CONSOLE',
  SPEEDOMETER: 'SPEEDOMETER',
  ENGINE: 'ENGINE',
  FRONT: 'FRONT',
  FRONT_LEFT: 'FRONT_LEFT',
  FRONT_RIGHT_SIDE: 'FRONT_RIGHT_SIDE',
  FRONT_LEFT_SIDE: 'FRONT_LEFT_SIDE',
  HOOD: 'HOOD',
  FRONT_INTERIOR: 'FRONT_INTERIOR',
  REAR_INTERIOR: 'REAR_INTERIOR',
  LEFT: 'LEFT',
  REAR: 'REAR',
  REAR_LEFT_SIDE: 'REAR_LEFT_SIDE',
  REAR_RIGHT_SIDE: 'REAR_RIGHT_SIDE',
  RIGHT: 'RIGHT',
  ROOF: 'ROOF',
  TRUNK: 'TRUNK',
  UNDERBODY: 'UNDERBODY',
  WHEELS_AND_BREAKS: 'WHEELS_AND_BREAKS',
  NAVIGATION_SYSTEM: 'NAVIGATION_SYSTEM',
  GENERAL: 'GENERAL',
} as const;

export const VEHICLE_MEDIA_PARTS = {
  A_PILLAR: 'A_PILLAR',
  ALTERNATOR: 'ALTERNATOR',
  ANTENNA: 'ANTENNA',
  B_PILLAR: 'B_PILLAR',
  BLINKER_LEFT: 'BLINKER_LEFT',
  BLINKER_RIGHT: 'BLINKER_RIGHT',
  BRAKE_FLUID_LEVEL: 'BRAKE_FLUID_LEVEL',
  BRAKE_LINES_BACK: 'BRAKE_LINES_BACK',
  BRAKE_LINES_FRONT: 'BRAKE_LINES_FRONT',
  BUMPER: 'BUMPER',
  C_PILLAR: 'C_PILLAR',
  CATALYTIC_CONVERTER: 'CATALYTIC_CONVERTER',
  COC: 'COC',
  CONVERTIBLE_TOP: 'CONVERTIBLE_TOP',
  COOLANT_LEVEL: 'COOLANT_LEVEL',
  COOLING_SYSTEM: 'COOLING_SYSTEM',
  COUPLING: 'COUPLING',
  D_PILLAR: 'D_PILLAR',
  DISC_DEAR_DOOR: 'DISC_DEAR_DOOR',
  DISC_FRONT_DOOR: 'DISC_FRONT_DOOR',
  DOOR_HANDLE_FRONT: 'DOOR_HANDLE_FRONT',
  DOOR_HANDLE_REAR: 'DOOR_HANDLE_REAR',
  DRIVE_HAFT_FRONT_RIGHT: 'DRIVE_HAFT_FRONT_RIGHT',
  DRIVE_SHAFT_FRONT_LEFT: 'DRIVE_SHAFT_FRONT_LEFT',
  DRIVE_SHAFT_REAR_LEFT: 'DRIVE_SHAFT_REAR_LEFT',
  DRIVE_SHAFT_REAR_RIGHT: 'DRIVE_SHAFT_REAR_RIGHT',
  ENGINE: 'ENGINE',
  ENGINE_OIL_LEVEL: 'ENGINE_OIL_LEVEL',
  EXHAUST_MANIFOLD: 'EXHAUST_MANIFOLD',
  EXTERIOR_PANORAMA_OPEN_DOORS_PART: 'EXTERIOR_PANORAMA_OPEN_DOORS_PART',
  EXTERIOR_PANORAMA_CLOSED_DOORS_PART: 'EXTERIOR_PANORAMA_CLOSED_DOORS_PART',
  FILLER_CAP: 'FILLER_CAP',
  FOG_LIGHT_LEFT: 'FOG_LIGHT_LEFT',
  FOG_LIGHT_RIGHT: 'FOG_LIGHT_RIGHT',
  FRONT_DOOR: 'FRONT_DOOR',
  FRONT_FENDER: 'FRONT_FENDER',
  FRONT_LEFT_BRAKE: 'FRONT_LEFT_BRAKE',
  FRONT_RIGHT_BRAKE: 'FRONT_RIGHT_BRAKE',
  FRONT_SHOCK_ABSORBER: 'FRONT_SHOCK_ABSORBER',
  FRONT_SILENCER: 'FRONT_SILENCER',
  HARDTOP: 'HARDTOP',
  HEADLIGHT_LEFT: 'HEADLIGHT_LEFT',
  HEADLIGHT_RIGHT: 'HEADLIGHT_RIGHT',
  HOOD: 'HOOD',
  INTERIOR_PANORAMA_FULL: 'INTERIOR_PANORAMA_FULL',
  LAMBDA_PROBE: 'LAMBDA_PROBE',
  MIRRORS_GLASS: 'MIRRORS_GLASS',
  MIRRORS_HOUSING: 'MIRRORS_HOUSING',
  PANORAMIC_ROOF: 'PANORAMIC_ROOF',
  POWER_STEERING_PUMP: 'POWER_STEERING_PUMP',
  PROPERLLER_SHAFT: 'PROPERLLER_SHAFT',
  RADIATOR: 'RADIATOR',
  RADIO: 'RADIO',
  REAR_DOOR: 'REAR_DOOR',
  REAR_FENDER: 'REAR_FENDER',
  REAR_SHOCK: 'REAR_SHOCK',
  REAR_SIDE_WINDOW: 'REAR_SIDE_WINDOW',
  REAR_SILENCER: 'REAR_SILENCER',
  REGISTRATION: 'REGISTRATION',
  RIM_FRONT_LEFT: 'RIM_FRONT_LEFT',
  RIM_FRONT_RIGHT: 'RIM_FRONT_RIGHT',
  ROOF_RAILS: 'ROOF_RAILS',
  SERVICE_BOOK: 'SERVICE_BOOK',
  SIDE_INDICATOR: 'SIDE_INDICATOR',
  SIDE_SKIRTS: 'SIDE_SKIRTS',
  SPOILER: 'SPOILER',
  STARTER: 'STARTER',
  STEERING_AND_TRACK_RODS: 'STEERING_AND_TRACK_RODS',
  STEERING_PUMP_OIL_LEVEL: 'STEERING_PUMP_OIL_LEVEL',
  SUNROOF: 'SUNROOF',
  TANK: 'TANK',
  TRANSMISSION: 'TRANSMISSION',
  TURBOCHARGER: 'TURBOCHARGER',
  TYRES_FRONT_LEFT: 'TYRES_FRONT_LEFT',
  TYRES_FRONT_RIGHT: 'TYRES_FRONT_RIGHT',
  WATER_PUMP: 'WATER_PUMP',
  WHEEL_BEARING_FRONT_LEFT: 'WHEEL_BEARING_FRONT_LEFT',
  WHEEL_BEARING_FRONT_RIGHT: 'WHEEL_BEARING_FRONT_RIGHT',
  WHEEL_BEARING_REAR_LEFT: 'WHEEL_BEARING_REAR_LEFT',
  WHEEL_BEARING_REAR_RIGHT: 'WHEEL_BEARING_REAR_RIGHT',
  WINDOW_FRAME: 'WINDOW_FRAME',
  WINDSHIELD: 'WINDSHIELD',
  WIPER_LEFT: 'WIPER_LEFT',
  WIPER_RIGHT: 'WIPER_RIGHT',
} as const;

export const MIME_TYPES = {
  JPEG: 'JPEG',
  PNG: 'PNG',
  MP4: 'MP4',
} as const;
