import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import kebabCase from 'lodash/kebabCase';

import type { VehicleImagePart } from 'gql/graphql';
import { FormUploader } from 'shared/components/FormUploader';
import { Typography } from 'shared/components/Typography';
import { useTranslation } from 'shared/hooks/useTranslation';
import { processHeicFile } from 'shared/components/FormUploader/processHeicFile';

import { CarGeneralPictureItem } from '../CarGeneralPictureItem';
import { VEHICLE_MEDIA_AREAS } from '../../constants';
import type { FileData } from '../../types';

import styles from './index.module.scss';

type Props = {
  qaIdPrefix?: string;
  stockNumber: string;
  title: string;
  optional?: boolean;
  id?: string;
};

const CarGeneralPictureSection: React.FC<Props> = ({
  title,
  qaIdPrefix = 'car-pictures',
  stockNumber,
  optional = false,
  id = '',
}) => {
  const { translations } = useTranslation();
  const { watch } = useFormContext();
  const area = VEHICLE_MEDIA_AREAS.GENERAL;
  const [images, setImages] = React.useState<
    Array<{ file?: File; absoluteUrl: string; id?: string } | FileData>
  >([]);

  const files: Array<FileData | File> = watch(area, []);

  const loadImageUrl = React.useCallback(
    (file: FileData | File) =>
      new Promise<{ file?: File; absoluteUrl: string }>((resolve, reject) => {
        if (!(file instanceof File)) {
          resolve(file);
        } else {
          const reader = new FileReader();
          reader.onload = (e) =>
            resolve({
              file,
              absoluteUrl: e.target?.result as string,
            });
          reader.onerror = (error) => reject(error);

          reader.readAsDataURL(file);
        }
      }),
    [],
  );

  React.useEffect(() => {
    let setImagesCallback = (
      filesWithLoadedImages: ReadonlyArray<{
        file?: File;
        absoluteUrl: string;
        id?: string;
      }>,
    ) => {
      const getImagesWithUniqueIds = (
        currentImages: ReadonlyArray<{
          file?: File;
          absoluteUrl: string;
          id?: string;
        }>,
      ) => {
        // Keep unique ids the same for existing images,
        // and generate new ones for new images
        return filesWithLoadedImages.map((loadedImage) => {
          if (!('file' in loadedImage) || !(loadedImage.file instanceof File)) {
            return loadedImage;
          }

          const imageWithSameFile = currentImages.find(
            (image) => image.file === loadedImage.file,
          );
          return {
            ...loadedImage,
            id:
              imageWithSameFile && 'id' in imageWithSameFile
                ? (imageWithSameFile.id as string)
                : loadedImage.file.name + Date.now(),
          };
        });
      };
      setImages(getImagesWithUniqueIds);
    };

    if (files) {
      Promise.all(files.map(loadImageUrl)).then((...args) => {
        setImagesCallback(...args);
      });
    }

    return () => {
      setImagesCallback = () => { };
    };
  }, [loadImageUrl, files]);

  React.useEffect(() => {
    if (images.length > 0 && (!files || !files.length)) {
      setImages([]);
    }
  }, [images, files]);
  
  return (
    <div data-qa-id={`${qaIdPrefix}-section-container`} id={id}>
      <Typography
        tag="div"
        variant="titleL"
        additonalClassNames={styles.title}
        data-qa-id={`${qaIdPrefix}-section-title`}
      >
        {title}{' '}
        {optional && (
          <span className={styles.optionalLabel}>{translations.OPTIONAL}</span>
        )}
      </Typography>
      <Typography
        tag="div"
        additonalClassNames={styles.subtitle}
        data-qa-id={`${qaIdPrefix}-main-subtitle`}
      >
        {translations.CAN_UPLOAD_MULTIPLE_PICTURES}
      </Typography>

      <div
        className={styles.grid}
        data-qa-id={`${qaIdPrefix}-section-grid-items`}
      >
        <FormUploader
          title={translations.FREE_UPLOAD}
          containerClassName={styles.uploaderContainer}
          extraClassName={styles.uploader}
          allowMultipleFiles
          uploadFileTypes="image/png, image/jpeg, image/heic, image/heif"
          processSelectedFile={processHeicFile}
          name={area}
          qaIdPrefix={`${qaIdPrefix}-multiple-uploader`}
          inputQaId={`${qaIdPrefix}-multiple-uploader-input`}
          includeCarousel={false}
        >
          {({ handleUploadClick }) => (
            <div
              className={styles.placeholderImg}
              onClick={(event) => {
                event.preventDefault();
                handleUploadClick();
              }}
            >
              <div className={styles.uploader}></div>
            </div>
          )}
        </FormUploader>

        {images.map((image, index) => {
          return (
            <CarGeneralPictureItem
              key={'id' in image ? image.id : image.absoluteUrl}
              name={area}
              image={image}
              index={index}
              mediaUrl={`/v1.1/car/${stockNumber}/image`}
              mediaData={{
                stockNumber,
                part: `vehicle__${area}`.toLowerCase() as VehicleImagePart,
              }}
              area={area}
              qaIdPrefix={`${qaIdPrefix}-${kebabCase(area)}`}
            />
          );
        })}
      </div>
    </div>
  );
};

export { CarGeneralPictureSection };
