const isProductionEnv = () => process.env.REACT_APP_DEPLOYMENT_ENV === 'prod';

/** ******************************* CONFIG AND URLS  ******************************** */

const waKey = process.env.REACT_APP_WA_KEY ?? '';

const AUTO1_TEST_ENDPOINT = 'https://auto1-training-1.auto1-test.com';

const AUTH_BASE_URL_CONFIG = Object.freeze({
  qa: 'https://api-eva-qa-1.1.qa.b2x.auto1.cloud/v1',
  prod: 'https://api-eva.auto1.com/v1',
});
const GRAPHQL_URL_CONFIG = Object.freeze({
  qa: `${AUTH_BASE_URL_CONFIG.qa}/pav3-gateway`,
  prod: `${AUTH_BASE_URL_CONFIG.prod}/pav3-gateway`,
});

const IMAGE_SERVICE_URL_CONFIG = Object.freeze({
  qa: `https://api-purchasing-aws-eu-qa-1.auto1-test.com/v1/media`,
  prod: `https://api-purchasing.auto1.com/v1/media`, // todo needs to be changed once we have new env endpoint
});

const GRAPHQL_ENDPOINT = isProductionEnv()
  ? GRAPHQL_URL_CONFIG.prod
  : GRAPHQL_URL_CONFIG.qa;
const AUTH_BASE_ENDPOINT = isProductionEnv()
  ? AUTH_BASE_URL_CONFIG.prod
  : AUTH_BASE_URL_CONFIG.qa;

const IMAGE_SERVICE_ENDPOINT = isProductionEnv()
  ? IMAGE_SERVICE_URL_CONFIG.prod
  : IMAGE_SERVICE_URL_CONFIG.qa;

const graphQLBaseUrl =
  process.env.NODE_ENV === 'development'
    ? `/graphql?wa_key=${waKey}`
    : `${GRAPHQL_ENDPOINT}?wa_key=${waKey}`;

const authBaseUrl =
  process.env.NODE_ENV === 'development' ? '/auth-service' : AUTH_BASE_ENDPOINT;

const auto1AccessTokenUrl =
  process.env.NODE_ENV === 'development'
    ? '/obtain-token'
    : '/en/app/user-token/obtain-token';

const mobileEvaluatorAccessTokenUrl = `${AUTH_BASE_ENDPOINT}/auth/oauth/token?wa_key=${waKey}`;

const auto1AccessTokenUrlParams = Object.freeze({
  credentials: 'include',
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    Accept: 'application/json',
  },
});

const authUrlParams = Object.freeze({ wa_key: waKey });

const imageServiceBaseUrl = () => {
  return process.env.IMAGE_SERVICE_URL || IMAGE_SERVICE_ENDPOINT;
};

const signinSettings = Object.freeze({
  email: process.env.REACT_APP_API_EMAIL || 'iapp_dev_web+2@auto1.com',
  password: '11auto11',
  url: '/obtain-cookie',
});

const APP_NAMES = Object.freeze({
  MERCHANT: 'MERCHANT',
  MOBILE_EVALUATOR: 'MOBILE_EVALUATOR',
});

const translationUrlParams = {
  wa_key: waKey,
};

const mediaUrlParams = {
  wa_key: waKey,
};

// Keep this as module.exports because it's used on the proxy nodejs server
export {
  APP_NAMES,
  GRAPHQL_ENDPOINT,
  AUTH_BASE_ENDPOINT,
  AUTO1_TEST_ENDPOINT,
  graphQLBaseUrl,
  authBaseUrl,
  auto1AccessTokenUrl,
  signinSettings,
  mobileEvaluatorAccessTokenUrl,
  auto1AccessTokenUrlParams,
  authUrlParams,
  imageServiceBaseUrl,
  translationUrlParams,
  mediaUrlParams,
};
