import last from 'lodash/last';
import first from 'lodash/first';

export const getDomainName = () => window.location.hostname;

export const getBaseName = (url: string) => last(url.split('/'));

export const getPdfFilename = (uploadUrl: string) =>
  first(uploadUrl.split('?'));

export const addQueryParams = (path: string, params: Record<string, string>) => {
  const [basePath, queryString = ''] = path.split('?');

  const searchParams = new URLSearchParams(queryString);

  Object.entries(params).forEach(([key, value]) => {
    if (value !== undefined && value !== null) {
      searchParams.set(key, String(value));
    }
  });

  const newQuery = searchParams.toString();
  return newQuery ? `${basePath}?${newQuery}` : basePath;
};
