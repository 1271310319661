import has from 'lodash/has';
import type { CarPicturesVehicleQuery } from 'gql/graphql';
import { mapHighlightsToForm } from 'pages/CarQuality/helpers';
import { VEHICLE_MEDIA_AREAS, VEHICLE_MIME_TYPE } from '../constants';

const getMimeType = (url: string) => {
  const [mimeType = ''] = url.split('.').slice(-1);

  if (
    [
      VEHICLE_MIME_TYPE.JPEG,
      VEHICLE_MIME_TYPE.PNG,
      VEHICLE_MIME_TYPE.JPG,
    ].includes(mimeType.toUpperCase() as 'JPEG' | 'PNG' | 'JPG')
  ) {
    const normalizedMimeType =
      mimeType.toUpperCase() === 'JPG' ? 'JPEG' : mimeType.toUpperCase();

    return normalizedMimeType as 'JPEG' | 'PNG';
  }

  return VEHICLE_MIME_TYPE.MP4;
};

const mapDataToFormValues = (
  inspection: CarPicturesVehicleQuery['inspection'],
  shouldUseNewAccidentModel: boolean,
) => {
  const images = inspection?.vehicle?.images ?? [];
  const highlights = shouldUseNewAccidentModel
    ? mapHighlightsToForm(inspection?.meta?.highlights || [])
    : null;
  const carGeneralImages = images
    .filter((image) => image.area === VEHICLE_MEDIA_AREAS.GENERAL)
    .map(({ __typename, ...image }) => image);
  const formData = images
    .filter(
      (image) => image && image.area && has(VEHICLE_MEDIA_AREAS, image.area),
    )
    .reduce((acc, { area, mimeType, absoluteUrl }) => {
      if (area === undefined || area === null) {
        return acc;
      }

      if (
        area === VEHICLE_MEDIA_AREAS.ENGINE &&
        mimeType === VEHICLE_MIME_TYPE.MP4
      ) {
        return {
          ...acc,
          [`${area}__VIDEO`]: absoluteUrl,
        };
      }
      if (area === VEHICLE_MEDIA_AREAS.GENERAL) {
        return {
          ...acc,
          [area]: carGeneralImages,
        };
      }

      return {
        ...acc,
        [area]: absoluteUrl,
      };
    }, {});
  return {
    ...formData,
    highlights,
  };
};

export { mapDataToFormValues, getMimeType };
