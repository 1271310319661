import * as React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import kebabCase from 'lodash/kebabCase';
import { apm } from '@elastic/apm-rum';
import { useTranslation } from 'shared/hooks/useTranslation';
import { useHistory } from 'react-router-dom';

import { appRoutes } from 'appRoutes';
import { useInspection } from 'shared/components/InspectionContext';
import { RoutesEnum } from 'shared/utils/route';
import { scrollToError } from 'shared/utils/scrollToError';
import { useSubmitTracking } from 'shared/hooks/useSubmitTracking';
import { useMultipleMediaUploader } from 'shared/utils/useMultipleMediaUploader';
import { fileFilter } from 'shared/utils/fileFilter';
import {
  useCarPicturesVehicleQuery,
  useReplaceMainCarImagesMutation,
  useReplaceAdditionalCarImagesMutation,
  useReplaceHighlightsMutation,
} from 'gql/graphql';
import type {
  HighlightInput,
  VehicleImageArea,
  VehicleImageInput,
} from 'gql/graphql';
import { mapHighlightsToMutation } from 'pages/CarQuality/helpers';
import { buildMultipleImageMapper } from 'pages/CarQuality/hooks/useCarQualityUpdater';
import { getMimeType, mapDataToFormValues } from '../helpers';
import {
  carPictureInsideItems,
  carPictureOutsideItems,
  carPictureOptionalItems,
} from '../carPicturesData';
import { CarPictureSchema } from '../carPicturesData';
import { VEHICLE_MEDIA_AREAS } from '../constants';
import { useProgress } from './useProgress';
import type { Form, FileData } from '../types';


const useCarPictures = () => {
  const history = useHistory();

  const {
    removeStepFromNotCompleted,
    stockNumber,
    isPdfInspection,
    inspectionId,
    shouldUseNewAccidentModel,
  } = useInspection();
  const { translations } = useTranslation();
  const submitContext = React.useRef<'save' | 'submit' | null>(null);
  const methods = useForm({
    context: submitContext,
    resolver: async (data, context, options) => {
      const isOptional = context?.current === 'save';

      return yupResolver(
        CarPictureSchema(
          translations,
          isOptional,
          isPdfInspection,
          shouldUseNewAccidentModel,
        ),
      )(data, context, options);
    },
  });
  const { submitTracking } = useSubmitTracking('carPhotos');
  const { uploadMedia: uploadMultipleMedia } = useMultipleMediaUploader(
    `/v1.1/car/${stockNumber}/image`,
  );

  const { loading } = useCarPicturesVehicleQuery({
    variables: {
      inspectionId,
    },
    onCompleted: (data) => {
      const formData = mapDataToFormValues(
        data?.inspection,
        shouldUseNewAccidentModel,
      );
      methods.reset(formData);
    },
  });

  const [submitPictures, { error: mutationMainImagesError }] =
    useReplaceMainCarImagesMutation();
  const [submitAdditionalImages, { error: mutationAdditionalImagesError }] =
    useReplaceAdditionalCarImagesMutation();
  const [submitHighlights, { error: mutationHighlightsError }] =
    useReplaceHighlightsMutation();
  const [uploadError, setUploadError] = React.useState('');
  const serverError =
    mutationMainImagesError?.graphQLErrors?.[0]?.message ??
    mutationAdditionalImagesError?.graphQLErrors?.[0]?.message ??
    mutationHighlightsError?.graphQLErrors?.[0]?.message ??
    uploadError;

  const insideSectionItems = React.useMemo(
    () => carPictureInsideItems(translations),
    [translations],
  );
  const outsideSectionItems = React.useMemo(
    () => carPictureOutsideItems(translations),
    [translations],
  );
  const optionalSectionItems = React.useMemo(
    () => carPictureOptionalItems(translations),
    [translations],
  );

  const setProgress = useProgress();

  const submitErrorHandler = (errors: object) => {
    const [firstErrorField] = Object.keys(errors);
    scrollToError(`[data-qa-id*="${kebabCase(firstErrorField)}"]`);
    setProgress(methods.getValues());
  };
  const submitHandler = async ({ highlights, ...formValues }: Form) => {
    const isOptional = submitContext?.current === 'save';
    const highlightsMutations = highlights
      ? mapHighlightsToMutation(highlights)
      : [];

    try {
      const carGeneralImages = fileFilter(
        formValues[VEHICLE_MEDIA_AREAS.GENERAL] ?? [],
      );
      const [carGeneralImagesToSave, highlightImagesToSave] = await Promise.all(
        [
          uploadMultipleMedia(
            carGeneralImages.files.map((file) => ({
              stockNumber,
              file,
              part: 'vehicle__general',
              source: 11,
            })),
          ).then((results) => [
            ...carGeneralImages.previouslyUploadedImages,
            ...results.map(
              ({
                data: {
                  wkda: { url },
                },
              }) => ({
                absoluteUrl: url,
                area: VEHICLE_MEDIA_AREAS.GENERAL,
                mimeType: getMimeType(url),
              }),
            ),
          ]),
          buildMultipleImageMapper(
            highlightsMutations,
            uploadMultipleMedia,
            stockNumber,
          ),
        ],
      );

      const reduce = (
        acc: Array<FileData>,
        key: keyof Omit<Form, 'highlights'>,
      ) => {
        if (!formValues[key] || key === VEHICLE_MEDIA_AREAS.GENERAL) return acc;
        const url = formValues[key] as string;
        return [
          ...acc,
          {
            absoluteUrl: url,
            area: key.replace('__VIDEO', '') as VehicleImageArea,
            mimeType: getMimeType(url),
          },
        ];
      };

      const images = (
        Object.keys(formValues) as Array<keyof Omit<Form, 'highlights'>>
      ).reduce<Array<FileData>>(reduce, []);
      await Promise.all([
        submitPictures({
          variables: {
            inspectionId,
            images,
            incomplete: isOptional,
          },
        }),
        submitAdditionalImages({
          variables: {
            inspectionId,
            images: carGeneralImagesToSave as VehicleImageInput[],
            incomplete: isOptional,
          },
        }),
        ...(shouldUseNewAccidentModel
          ? [
              submitHighlights({
                variables: {
                  inspectionId,
                  incomplete: isOptional,
                  highlights: highlightImagesToSave as HighlightInput[],
                },
              }),
            ]
          : []),
      ]);

      methods.reset({ ...formValues, highlights });
      removeStepFromNotCompleted(RoutesEnum.CAR_PICTURES);
      if (!isOptional) {
        submitTracking();
        history.push(appRoutes.carQuality(`${inspectionId}`, true));
      }
    } catch (e) {
      setUploadError((e as Error).message);
      apm.captureError(e as Error);
    }
  };

  return {
    methods,
    loading,
    stockNumber,
    submitErrorHandler,
    submitHandler,
    submitContext,
    insideSectionItems,
    outsideSectionItems,
    optionalSectionItems,
    serverError,
  };
};

export { useCarPictures };
