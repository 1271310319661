import * as React from 'react';
import cn from 'classnames';
import { Controller, useFormContext } from 'react-hook-form';
import { MediaUploader } from '@auto1-ui/mediauploader';
import { heicConvert } from 'shared/utils/heic-convert';
import { useTranslation } from 'shared/hooks/useTranslation';

type SourceType = NonNullable<
  React.ComponentProps<typeof MediaUploader>['sourceType']
>;

type Props = {
  qaIdPrefix?: string;
  name: string;
  sourceType: SourceType;
  errorClassName?: string;
  placeHolderAdditionalClassName?: string;
  hasValueClassName?: string;
  placeHolder: React.ReactNode;
  allowMultiplePictures?: boolean;
  onIsProcessingChanged?: (isProcessing: boolean) => void;
} & Partial<React.ComponentProps<typeof MediaUploader>>;

const FormMediaUploader: React.FC<Props> = ({
  name,
  sourceType,
  errorClassName = '',
  placeHolderAdditionalClassName = '',
  hasValueClassName = '',
  placeHolder,
  qaIdPrefix = '',
  allowMultiplePictures = false,
  onIsProcessingChanged,
  ...props
}) => {
  const {
    control,
    watch,
    formState: { errors },
    setError,
    clearErrors,
  } = useFormContext();
  const { translations } = useTranslation();
  let hasValue = watch(name);
  hasValue = Array.isArray(hasValue) ? hasValue.length > 0 : hasValue;

  const error = errors[name];

  const handleChange = React.useCallback(
    ({ target: { files } }: React.ChangeEvent<HTMLInputElement>) => {
      if (!files || !files.length) return null;
      const [file] = files;
      return file;
    },
    [],
  );
  const handleUpload =
    (callback: (file: File) => void) =>
      async (e: React.ChangeEvent<HTMLInputElement>) => {
        clearErrors();

        const file: File | null = handleChange(e);

        if (file === null) {
          return;
        }

        if (
          file.type !== 'image/heic' &&
          file.type !== 'image/heif' &&
          !file.name.toLowerCase().endsWith('.heic') &&
          !file.name.toLowerCase().endsWith('.heif')
        ) {
          return callback(file);
        }

        try {
          onIsProcessingChanged?.(true);
          callback(await heicConvert(file));
          onIsProcessingChanged?.(false);
        } catch (e) {
          onIsProcessingChanged?.(false);
          setError(name, {
            message: translations.CAR_PICTURE_HEIC_CONVERSION_ERROR,
            type: 'convert',
          });
        }
      };

  return (
    <Controller
      render={({ field: { value, onChange, ref, ...renderProps } }) => (
        <MediaUploader
          {...{ [sourceType === 'image' ? 'imageSrc' : 'videoSrc']: value }}
          {...renderProps}
          onUpload={handleUpload(onChange)}
          qaIdPrefix={qaIdPrefix}
          sourceType={sourceType}
          inputProps={{
            multiple: allowMultiplePictures,
            accept:
              sourceType === 'video'
                ? 'video/mp4'
                : 'image/png,image/jpeg,image/heic,image/heif',
          }}
          placeHolderAdditionalClassName={cn(placeHolderAdditionalClassName, {
            [errorClassName]: error,
            [hasValueClassName]: hasValue,
          })}
          placeHolder={!hasValue && placeHolder}
          {...props}
        />
      )}
      control={control}
      name={name}
      defaultValue={null}
      shouldUnregister
    />
  );
};

export { FormMediaUploader };
